import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, Link } from 'gatsby';
import Layout from '../../components/Layout';
import { SiteData } from '../../types/QueryTypes';
import Container from './style';

type Props = {
  data: SiteData;
};

const TopicPost: React.FC<Props> = ({ data }) => {
  /* eslint-disable react/no-danger */
  return (
    <Layout>
      <Helmet>
        <title>{data.markdownRemark.frontmatter.title}</title>
      </Helmet>
      <Container>
        <div className="container">
          <div className="section-content">
            <div className="topics-content">
              <h2>{data.markdownRemark.frontmatter.title}</h2>
              <h3>{data.markdownRemark.frontmatter.date}</h3>
              <div
                dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
              />
              <div id="return">
                <Link to="/">Homeへ</Link>
              </div>
            </div>
          </div>
          <div className="silhouette-container">
            <span className="silhouette-image" />
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default TopicPost;

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt
      html
      frontmatter {
        title
        date(formatString: "YYYY/MM/DD")
      }
    }
  }
`;
